<template>
  <section class="home-page" ref="scrollTarget" style="min-height:100vh;">

    <HeaderNav title="Privacy Policy"></HeaderNav>

    <q-page-container>
      <q-page class="q-pa-sm" :style-fn="pageHeight">

        <section class="page-container is-fullwidth text-center q-pb-xl q-pt-xl">
          <h2 class="text-weight-medium">Privacy Policy</h2>

          <div class="text-left">
          </div>

        </section>

      </q-page>
    </q-page-container>

    <FooterNav class="q-mt-xl"></FooterNav>

  </section>
</template>

<script>
import { mapState } from "vuex";

import HeaderNav from '@/components/blocks/HeaderNav.vue';
import FooterNav from '@/components/blocks/FooterNav.vue';

export default {
  head() {
    return {
      title: 'Privacy Policy',
      titleTemplate: (title) => `${title} | Sword Moon`,
      meta: [
      ],
    };
  },
  components: {
    HeaderNav,
    FooterNav,
  },
  beforeMount() {
  },
  async mounted() {
  },
  data() {
    return {
      URL,
    };
  },
  computed: {
  },
  methods: {
    pageHeight(offset) {
      return { minHeight: `calc(100vh - 49px);` };
    },
  },
  watch: {
  }
};
</script>

<style scoped lang="scss">
a {
  text-decoration: underline!important;
}
</style>

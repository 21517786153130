<template>
  <q-card class="q-pa-md" >
    <div :id="`waveform-${uuid}`" v-if="uuid"></div>
    <div class="flex justify-between items-center">
      <div class="flex flex-center">
        <div :id="`waveform-${uuid}-time`" class="waveform_time">0:00 / 0:00</div>
      </div>
      <q-btn
          :size="mini ? 'xs' : 'md'"
          round
          dense
          text-color="accent"
          color="grey-3"
          @click="
            () => {
              if (wavesurfer.isPlaying()) {
                wavesurfer.pause();
                isPlaying = false;
              } else {
                wavesurfer.play();
                isPlaying = true;
              }
            }
          "
          :icon="isPlaying ? 'mdi-pause' : 'mdi-play'"
        />
    </div>
  </q-card>
</template>

<script>
import WaveSurfer from 'wavesurfer.js';
import uuid from 'uuid-v4';

import { FormatPlayerTime } from '@/helpers';

export default {
  data() {
    return {
      isPlaying: false,
      wavesurfer: null,
      FormatPlayerTime,
      uuid: uuid(),
    };
  },
  props: {
    audioURL: {
      type: String,
      required: true,
    },
    duration: {
      type: Number,
      default: 0,
    },
    mini: {
      type: Boolean,
      default: false,
    },
    autoplay: {
      type: Boolean,
      default: false,
    }
  },
  mounted() {
    let options = {
      container: document.getElementById(`waveform-${this.uuid}`),
      url: this.audioURL,
      backend: 'MediaElement',
      barWidth: 8,
      cursorWidth: 4,
      cursorColor: '#d5c778',
      barGap: 2,
      barRadius: 8,
      waveColor: '#717171',
      progressColor: '#d5c778',
      height: 120,
      autoplay: false,
    };
    if (this.mini) {
      options = {
        ...options,
        height: 40,
        barWidth: 2,
        cursorWidth: 1,
        barGap: 1,
        barRadius: 2,
      };
    }
    this.wavesurfer = WaveSurfer.create(options);
    const timeEl = document.getElementById(`waveform-${this.uuid}-time`);
    this.wavesurfer.on('timeupdate', (currentTime) => {
      timeEl.textContent = `${FormatPlayerTime(currentTime)} / ${FormatPlayerTime(this.wavesurfer.getDuration())}`;
    });
    this.wavesurfer.on('ready', () => {
      timeEl.textContent = `0:00 / ${FormatPlayerTime(this.wavesurfer.getDuration())}`;

      if (this.autoplay) {
        this.wavesurfer.play();
        this.isPlaying = true;
      }
    });
  },
  unmounted() {
    this.wavesurfer.destroy();
  },
};
</script>

<template>
  <section></section>
</template>

<script>
export default {
  head() {
    return {
      title: 'Logout',
      titleTemplate: (title) => `${title} | Sword Moon`,
      meta: [
        { name: 'robots', content: 'noindex' },
      ],
    };
  },
  created() {
    this.logout();
  },
  unmounted() {
  },
  data() {
    return {};
  },
  computed: {
  },
  methods: {
    logout() {
      this.$store.dispatch('logout');
      this.$router.push('/');
    }
  },
  watch: {
  },
};
</script>

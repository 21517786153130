// firebaseListeners.js

import moment from "moment";
import * as Sentry from '@sentry/vue';

import { Capacitor } from "@capacitor/core";
import { Dialog } from 'quasar';

import {
  Timestamp,
  firestore,
  auth,
  onAuthStateChanged,
  doc,
  collection,
  limit,
  onSnapshot,
  orderBy,
  query,
  where,
  getDocs,
} from '@/firebaseConfig.js';

import { GetVersion, SendSuccess } from "@/helpers.js";

import router from '@/router/router';
import store from '@/store/store';

let userListener = null;
let sessionsListener = null;
let serversListener = null;
let messagesListener = null;

let refreshedUnix = moment().unix();

const listenerError = async (err) => {
  console.error(err);
  Sentry.captureException(err);
  // Reload page
  await Dialog.create({
    title: '📡 No Connection',
    message: `We're having trouble connecting to the servers at the moment. Try refreshing the page and try again.`,
    html: true,
    fullWidth: false,
  }).onOk(async () => {
    // Refresh page if they hit continue
    location.reload();
  }).onCancel(() => {
  });
};

// Auth State Change
const authStateChange = async (user) => {
  // Initialize
  if (!user) {
    return;
  }

  // Set current user (MUST BE FIRST)
  store.commit('setCurrentUser', user);

  // Set Sentry
  Sentry.setUser({
    ip_address: '{{auto}}',
    username: user.displayName,
    email: user.email,
    id: user.uid,
    release: GetVersion(),
  });

  // Set Canny
  /* try {
    window.Canny('identify', {
      appID: '655f8bc9b9e1e8d4ae7f1c7a',
      user: {
        email: user.email,
        name: user.name,
        id: user.uid,
        avatarURL: user.photoURL || '',
        created: moment.unix(user.metadata.createdAt).toISOString(),
      },
    });
  } catch(err) {
    console.error(err);
    Sentry.captureException(err);
  } */
  //

  // Update or create user
  try {
    let promises = [
      store.dispatch('updateUserProfile', {
        accessedAt: Timestamp.now(),
      }),
    ];
    await Promise.allSettled(promises);
  } catch(err) {
    await store.dispatch('logout');
    await router.push('/');
  }

  createUserListener(user);
  createSessionsListener(user);
  createServersListener(user);

  // User Details Check
  if (store.state.isLoggingIn) {
    // This is the user from Firebase, not Firestore

    // Route to /onboarding
    // Pass over url params
    const params = Object.fromEntries(new URLSearchParams(new URL((window.location.href).toLowerCase()).search).entries());
    await router.push({
      path: '/',
      query: params,
    });

    store.commit('isLoggingIn', false);
    store.commit('setDrawer', true);
    SendSuccess('Logged In Successfully');
  }
};

onAuthStateChanged(auth, async user => {
  await authStateChange(user);
});

const removeAllListeners = async () => {
  if (userListener) {
    userListener();
  }
  if (sessionsListener) {
    sessionsListener();
  }
  if (serversListener) {
    serversListener();
  }
  if (messagesListener) {
    messagesListener();
  }
};

const createUserListener = async(user) => {
  // Create User Listener
  userListener = onSnapshot(doc(firestore, 'users', user.uid), async (doc) => {
    console.log('User Updated');

    let userProfile = doc.data();

    if (userProfile) {
      userProfile.id = doc.id;
      store.commit('setUserProfile', userProfile);

      // Check for data refresh
      if (userProfile.refreshAt?.seconds * 1000 > refreshedUnix) {
        refreshedUnix = userProfile.refreshAt.seconds*1000;
      }
    }
  }, async (err) => {
    await listenerError(err);
  });
};

const createSessionsListener = async(user) => {
  // Create Sessions Listener
  const sessionsQuery = query(collection(firestore, 'sessions'), where('uid', '==', user.uid), orderBy('createdAt', 'desc'), limit(20));
  sessionsListener = onSnapshot(sessionsQuery, async (snapshot) => {
    console.log('Sessions Updated');

    let sessions = [];
    snapshot.forEach((doc) => {
      let session = doc.data();
      session.id = doc.id;
      sessions.push(session);
    });

    // Get last message for each session
    let lastMessages = {};
    for (let session of sessions) {
      const messagesQuery = query(collection(doc(firestore, 'sessions', session.id), 'messages'), orderBy('createdAt', 'desc'), limit(1));
      const messagesSnapshot = await getDocs(messagesQuery);
      messagesSnapshot.forEach((doc) => {
        session.lastMessage = doc.data();
      });
    }

    store.commit('setSessions', sessions);
  }, async (err) => {
    await listenerError(err);
  });
};

const createServersListener = async (user) => {
  // Create Servers Listener
  const serversQuery = query(collection(firestore, 'servers'), where('uid', '==', user.uid), orderBy('createdAt', 'desc'));
  serversListener = onSnapshot(serversQuery, async (snapshot) => {
    console.log('Servers Updated');

    let servers = [];
    snapshot.forEach((doc) => {
      let server = doc.data();
      server.id = doc.id;
      servers.push(server);
    });

    store.commit('setServers', servers);
  }, async (err) => {
    await listenerError(err);
  });
};

const createMessageListener = async (sessionId) => {
  if (!sessionId) {
    return;
  }

  // Clear last listener
  if (messagesListener) {
    await removeMessageListener();
  }

  const messagesQuery = query(collection(doc(firestore, 'sessions', sessionId), 'messages'), orderBy('createdAt', 'asc'));
  messagesListener = onSnapshot(messagesQuery, async (snapshot) => {
    console.log('Messages Updated');

    let messages = [];
    snapshot.forEach((doc) => {
      let message = doc.data();
      message.id = doc.id;
      messages.push(message);
    });

    // Add messages to session
    store.commit('setMessages', { sessionId, messages });

    /*
    const sessionIndex = store.state.sessions.findIndex(session => session.sessionId === sessionId);
    if (sessionIndex !== -1) {
      let updatedSessions = [...store.state.sessions];
      updatedSessions[sessionIndex] = { ...updatedSessions[sessionIndex], messages };
      store.commit('setSessions', updatedSessions);
    }*/

  }, async (err) => {
    await listenerError(err);
  });

};

const removeMessageListener = async () => {
  if (messagesListener) {
    messagesListener();
  }
};

export {
  removeAllListeners,
  authStateChange,
  createMessageListener,
  removeMessageListener,
};

<template>
  <section ref="scrollTarget">

    <HeaderNav :title="'Not Found'"></HeaderNav>

    <q-page-container class="page-container">
      <q-page>

        <div class="row q-mb-lg">
          <div class="col-12 text-center">
            <h1>404</h1>
            <q-btn class="q-mt-lg full-width" unelevated rounded color="accent" text-color="white" size="lg"
                   type="button"
                   label="Go Back"
                   @click="goBack()">
            </q-btn>
          </div>
        </div>

      </q-page>
    </q-page-container>

  </section>

</template>

<script>
import { mapState } from "vuex";

import HeaderNav from '@/components/blocks/HeaderNav.vue';

import { SendSuccess, SendError } from "@/helpers.js";

export default {
  head() {
    return {
      title: '404',
      titleTemplate: (title) => `${title} | Sword Moon`,
      meta: [
        { name: 'robots', content: 'noindex' },
      ],
    };
  },
  components: {
    HeaderNav,
  },
  async beforeMount() {
  },
  async mounted() {
  },
  data() {
    return {
      model: null,
    };
  },
  computed: {
    ...mapState([
      'userProfile',
      'currentUser',
    ])
  },
  methods: {
    async goBack() {
      this.$router.push('/');
    },
  },
};
</script>

<style scoped lang="scss">
</style>

<template>
  <transition-group name="fade">
    <div id="q-app" v-if="appLoaded">
      <q-layout view="hHh LpR fFf">
        <!-- <video v-if="isDarkMode" id="background-video" preload="none" class="fixed"
               :poster="`/images/hero-1$-dark.png`" autoplay loop muted >
          <source :src="`/videos/hero-1-dark.mp4`" type="video/mp4">
        </video>-->
        <img id="background-video" :src="`/images/home-image-${randomImageNumber}.png`" class="fixed" :style="`filter: opacity(${isDarkMode ? '0.5' : '0.75'})`">
        <router-view class="navbar-main" :key="$route.path"/>
      </q-layout>
      <q-inner-loading
          :showing="isLoading"
          label="Please wait..."
          label-class="text-dark"
          label-style="font-size: 1.1em"
      />
    </div>
  </transition-group>
</template>

<script>
import { mapState, mapGetters } from 'vuex';
import moment from "moment";

export default {
  components: {
  },
  computed: {
    ...mapState([
      'appLoaded',
      'isLoading',
      'currentUser',
    ]),
    isDarkMode() {
      return this.$q.dark.isActive;
    },
    isMobile() {
      return (window.innerWidth < 1024);
    },
  },
  data() {
    return {
      currentTimeInterval: null,
      randomImageNumber: 1,
    };
  },
  methods: {
  },
  async beforeCreate() {
  },
  async beforeMount() {
    this.randomImageNumber = Math.floor(Math.random() * 7) + 1;

    if (this.$cookies.get('darkmode')) {
      const darkMode = (this.$cookies.get('darkmode') === 'true');
      this.$q.dark.set(darkMode);
    } else {
      this.$q.dark.set(false);
    }

    this.$store.commit('setDrawer', false);
  },
  mounted() {
    this.currentTimeInterval = setInterval(() => {
      this.$store.commit('setCurrentTime', moment());
    }, 1000);
  },
  unmounted() {
    if (this.currentTimeInterval) {
      clearInterval(this.currentTimeInterval);
    }
  },
  watch: {
  },
};
</script>

<style scoped lang="scss">
#background-video {
  width: 100vw;
  height: 100vh;
  object-fit: cover;
  position: fixed;
  left: 0;
  right: 0;
  top: 0;
  bottom: 0;
  z-index: -1;
}
</style>
<template>
  <section class="q-custom-content">
    <q-card class="rounded-borders q-ml-sm q-mr-sm q-mb-sm bg-glass">
      <q-card-section class="q-py-none">
        <q-item class="q-pa-none">
          <q-item-section class="q-pa-none">
            <h5 class="q-my-none">Profile</h5>
          </q-item-section>
        </q-item>
      </q-card-section>

      <q-separator />

      <q-form @submit="updateUser" class="q-gutter-y-md">
        <q-card-section>
          <div class="row q-col-gutter-sm">
            <div class="col-4 col-lg-4 col-md-4 col-sm-6 col-xs-12">
              <ProfilePicture class="q-ml-auto q-mr-auto q-mt-lg q-mb-lg"></ProfilePicture>
            </div>
            <div class="col-8 col-lg-8 col-md-8 col-sm-6 col-xs-12">
              <h5 class="q-my-sm">Details</h5>
              <q-input
                  class="q-mb-sm"
                  outlined
                  label="Email"
                  required
                  type="email"
                  v-model.trim="email"
              />
              <q-input
                  class="q-mb-sm"
                  outlined
                  label="Name"
                  required
                  type="text"
                  v-model.trim="name" />
            </div>
          </div>

          <q-btn
              unelevated
              class="full-width"
              rounded
              color="accent"
              text-color="white"
              type="submit"
              :disabled="isLoading">
            Save
          </q-btn>
        </q-card-section>
      </q-form>
    </q-card>

    <q-card class="rounded-borders q-ml-sm q-mr-sm q-mb-sm bg-glass">
      <q-card-section>
        <h6 class="q-my-sm">My Voice</h6>

        <Player v-if="audioURL" :audioURL="audioURL"/>

        <q-separator class="q-my-md" spaced v-if="audioURL" />

        <span class="text-accent">{{userProfile.transcript}}</span>

        <q-separator class="q-my-md" spaced v-if="userProfile.transcript" />

        <q-btn
            unelevated
            class="full-width"
            rounded
            color="accent"
            text-color="white"
            :disabled="recordStatus !== 'NONE'"
            @click="openSpeechRecordModal"
        >
          {{userProfile.transcript ? 'Relearn' : 'Learn'}} My Voice
        </q-btn>
      </q-card-section>
    </q-card>
    <q-card class="rounded-borders q-ma-sm bg-transparent">
      <q-btn
          rounded
          class="full-width"
          :disabled="isLoading"
          @click="logout"
      >
        Logout
      </q-btn>
    </q-card>
  </section>
</template>

<script>
import { mapState, mapGetters } from 'vuex';
import { SendError, SendSuccess } from "@/helpers.js";

import Player from '@/components/blocks/Player.vue';
import SpeechRecordModal from '@/components/modals/SpeechRecordModal.vue';
import ProfilePicture from '@/components/blocks/ProfilePicture.vue';

export default {
  components: {
    Player,
    ProfilePicture,
  },
  async beforeMount() {
    await this.setDefaults();
  },
  async mounted() {
  },
  async unmounted() {
  },
  data() {
    return {
      URL,
      isLoading: false,
      name: '',
      email: '',
      bioSummary: '',
      audioURL: '',
    };
  },
  computed: {
    ...mapState([
      'userProfile',
      'currentUser',
      'recordStatus',
      'servers',
    ]),
    ...mapGetters([
      'activeServers',
    ]),
    isMobile() {
      return (window.innerWidth < 1024);
    },
  },
  methods: {
    async setDefaults() {
      if (this.userProfile.name) {
        this.name = this.userProfile.name;
      }
      if (this.userProfile.email) {
        this.email = this.userProfile.email;
      }
      if (this.userProfile.audioURL) {
        this.audioURL = this.userProfile.audioURL;
      }
      this.isLoading = false;
    },
    async updateUser() {
      try {
        this.isLoading = true;
        await this.$store.dispatch('updateUserProfile', {
          name: this.name,
          email: this.email,
        });
        SendSuccess(`Successfully Updated Profile`);
        setTimeout(() => this.setDefaults(), 100);
      } catch (err) {
        SendError(err);
      }
      this.isLoading = false;
    },
    async logout() {
      this.$router.push('/logout');
    },
    openSpeechRecordModal() {
      this.$q.dialog({
        component: SpeechRecordModal,
      }).onOk(() => {
        console.log('OK');
        this.audioURL = '';
        setTimeout(() => this.setDefaults(), 100);
      }).onCancel(() => {
        console.log('Cancel');
      }).onDismiss(() => {
        console.log('Dismiss: OK/Cancel');
      });
    },
  },
  watch: {
    userProfile: {
      handler(newItem, oldItem) {
        this.setDefaults();
      },
      deep: true,
    },
  }
};
</script>

<style scoped lang="scss">
</style>
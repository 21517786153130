class VideoPlayerQueue {
  constructor(element, historyMessages = []) {
    this.queue = []; // Queue to hold the video messages
    this.history = [...historyMessages]; // History to hold the played video messages
    this.historyIndex = this.history.length - 1; // Index to track the current position in the history
    this.isPlaying = false; // Flag to track playback status
    this.isMuted = false; // Flag to track mute status

    this.element = element;

    if (this.history.length > 0) {
      this.historyIndex = this.historyIndex - 1;
      this.playNext();
    } else {
      this.text = '';
    }

    // You can use () when calling to avoid binding
    // this.playNext = this.playNext.bind(this);
    // this.playPrevious = this.playPrevious.bind(this);
  }

  // Method to add video message to the queue and start playback if not already playing
  enqueue(videoMessage) {
    this.queue.push(videoMessage); // Add the new video message to the queue
    if (!this.isPlaying) {
      this.playNext(); // Start playback if not already playing
    }
  }

  // Method to check if it's possible to play the previous video in the history
  canPlayPrevious() {
    return this.historyIndex > 0;
  }

  // Method to check if it's possible to play the next video in the history or queue
  canPlayNext() {
    return this.historyIndex < this.history.length - 1 || this.queue.length > 0;
  }

  // Method to play the previous video in the history
  playPrevious() {
    if (this.historyIndex > 0) {
      this.historyIndex--;
      this.playVideo(this.history[this.historyIndex]);
    }
  }

  // Method to play the next video in the history or queue
  playNext() {
    if (this.historyIndex < this.history.length - 1) {
      // There is a next video in the history
      this.historyIndex++;
      this.playVideo(this.history[this.historyIndex]);
    } else if (this.queue.length > 0) {
      // There is no next video in the history, but there is a next video in the queue
      const videoMessage = this.queue.shift(); // Remove the first video message from the queue
      this.history.push(videoMessage); // Add the video message to the history
      this.historyIndex++;
      this.playVideo(videoMessage);
    } else {
      // There is no next video in the history or queue
      this.isPlaying = false;
    }
  }

  // Method to play a video message
  playVideo(videoMessage) {
    this.isPlaying = true;
    this.element.src = videoMessage.videoURL; // Set the source of the video element
    this.element.preload = 'auto'; // Preload the video
    this.text = videoMessage.text;
    this.element.load();
    this.element.play().then(() => {
      console.log('Playing Video');
    }).catch((error) => {
      console.error('Error playing video:', error);
    });

    this.element.onended = () => {
      console.log('Video ended');
      this.playNext(); // Play the next video when the current one ends
    };
  }

  pause() {
    this.element.pause();
    this.isPlaying = false;
  }

  resume() {
    this.element.play();
  }

  clear() {
    this.queue = [];
    this.history = [];
    this.historyIndex = -1;
  }

  setMuted(isMuted) {
    this.isMuted = isMuted;
    this.element.muted = isMuted;
  }
}

export default VideoPlayerQueue;
import { VoiceRecorder } from 'capacitor-voice-recorder';
import uuid from 'uuid-v4';
import { Timestamp } from '@/firebaseConfig.js';
import { FormatDate } from '@/helpers';

export class VoiceRecorderWrapper {
  constructor(setState) {
    this.setState = setState;
  }

  async startRecording() {
    await VoiceRecorder.startRecording();
    this.setState('RECORDING');
  }
  async resumeRecording() {
    await VoiceRecorder.resumeRecording();
    this.setState('RECORDING');
  }
  async pauseRecording() {
    await VoiceRecorder.pauseRecording();
    this.setState('PAUSED');
  }

  async stopRecording() {
    const result = await VoiceRecorder.stopRecording();
    this.setState('NONE');
    if (result.value?.recordDataBase64) {
      const url = `data:${result.value.mimeType};base64,${result.value.recordDataBase64}`;
      const id = uuid();
      const now = Timestamp.now();
      return {
        status: 'local',
        id,
        createdAt: now,
        name: `Untitled_${FormatDate(now, 'YYYY-MM-DD_HHmm')}`,
        audioURL: url,
        mimeType: result.value.mimeType,
        duration: result.value.msDuration,
      };
    }
  }

  static async getCurrentStatus() {
    const { status } = await VoiceRecorder.getCurrentStatus();
    return status;
  }
}

<template>
  <section class="q-custom-content">
    <div class="page-container text-center is-fullwidth">

      <div class="q-mb-xl">
        <!--<span class="text-h3">⚔️🌙</span>-->
        <h1 class="text-weight-bolder">
          Meet Your <br>Inner Ally
        </h1>
        <p class="q-mx-xl q-mb-xl">Engage with a voice that mirrors yours, guiding you through self-reflection as you await or undergo therapy. It’s like having a conversation with a wiser version of yourself, helping illuminate the path to well-being.</p>
        <q-btn icon="mdi-arrow-top-right"
               :class="(isDarkMode ? 'bg-dark' : 'bg-light')" rounded unelevated
               :to="'/signup'">
          Hear Yourself Heal
        </q-btn>
      </div>

      <!-- -->

      <h2 class="margin-y text-bold">How It Works</h2>

      <div class="row q-mb-md q-pb-md" style="overflow-x:hidden;">
        <div class="col-xl-6 col-lg-6 col-md-6 col-sm-6 col-xs-12 fade-in fade-in fade-in-right">
          <q-img
              class="home-image"
              :src="`/images/home-image-3.png`">
            <video class="home-image absolute-center" :controls="false" autoplay loop muted  style="opacity:0.6">
              <source :src="`/videos/video-1.mp4`" type="video/mp4">
            </video>
            <div class="absolute-center rounded-borders bg-glass">
              <h2>1.<br> Personalized Voice Interaction.</h2>
            </div>
          </q-img>
        </div>
        <div class="col-xl-6 col-lg-6 col-md-6 col-sm-6 col-xs-12 fade-in fade-in-left" style="align-content: center">
          <q-item class="q-ma-sm q-py-xl bg-glass rounded-borders">
            <q-item-section>
              <h5 class="q-mt-none fade-in fade-in-left-delay">Your character is completely personalized in likeness.</h5>
              Utilizing advanced voice replication technology, this feature enables users to converse with the AI using a digital version of their own voice. This personalized approach helps foster a deeper sense of comfort and connection, making sessions feel more intimate and engaging.
            </q-item-section>
          </q-item>
        </div>
      </div>

      <div class="row q-mb-md q-pb-md" style="overflow-x:hidden;">
        <div class="col-xl-6 col-lg-6 col-md-6 col-sm-6 col-xs-12 order-xs-last order-lg-first fade-in fade-in-right" style="align-content: center">
          <q-item class="q-ma-sm q-py-xl bg-glass rounded-borders">
            <q-item-section>
              <h5 class="q-mt-none fade-in fade-in-right-delay">Your session exists privately on your own dedicated server.</h5>
              Never lose your place. Your AI Ally remembers where you left off in your last conversation, allowing you to resume your session seamlessly and continue your therapeutic journey without repetition or loss of context.
            </q-item-section>
          </q-item>
        </div>
        <div class="col-xl-6 col-lg-6 col-md-6 col-sm-6 col-xs-12 fade-in fade-in-left">
          <q-img
              class="home-image"
              :src="`/images/home-image-3.png`">
            <video class="home-image absolute-center" :controls="false" autoplay loop muted  style="opacity:0.6">
              <source :src="`/videos/video-2.mp4`" type="video/mp4">
            </video>
            <div class="absolute-center rounded-borders bg-glass">
              <h2>2.<br> Resume Sessions.</h2>
            </div>
          </q-img>
        </div>
      </div>

      <div class="row q-mb-md q-pb-md" style="overflow-x:hidden;">
        <div class="col-xl-6 col-lg-6 col-md-6 col-sm-6 col-xs-12 fade-in fade-in-right">
          <q-img
              class="home-image"
              :src="`/images/home-image-5.png`">
            <video class="home-image absolute-center" :controls="false" autoplay loop muted  style="opacity:0.6">
              <source :src="`/videos/video-3.mp4`" type="video/mp4">
            </video>
            <div class="absolute-center rounded-borders bg-glass">
              <h2>3. Mindful Minutes.</h2>
            </div>
          </q-img>
        </div>
        <div class="col-xl-6 col-lg-6 col-md-6 col-sm-6 col-xs-12 fade-in fade-in-left" style="align-content: center">
          <q-item class="q-ma-sm q-py-xl bg-glass rounded-borders">
            <q-item-section>
              <h5 class="q-mt-none  fade-in fade-in-left-delay">Have a deep inner-reflection by typing or speaking your thoughts.</h5>
              Empower your journey with the freedom to choose exactly how much time you want to spend, without the commitment of a subscription. Enjoy the cost-effective benefits of paying only for what you need, whenever you need it.
            </q-item-section>
          </q-item>
        </div>
      </div>

      <!-- -->

      <h2 class="margin-y text-bold">Pricing</h2>

      <div class="row q-mb-md q-pb-md">
        <div class="offset-xl-3 offset-lg-3 offset-sm-3 col-xl-6 col-lg-6 col-md-6 col-sm-6 col-xs-12">
          <div class="q-ma-sm">
            <div class="bg-glass q-pa-lg full-width bg-glass rounded-borders relative-position full-width fade-in fade-in-down" >
              <h2 class="q-pt-xl q-pb-none q-mb-none">$<span style="font-size:128px;">0.10</span></h2>
              <div>/per minute</div>
              <div class="q-mt-lg q-mb-xl text-subtitle2 text-center text-secondary text-weight-light">* minimum 10 minute purchase, no subscriptions, pay-per-use</div>
            </div>
          </div>

        </div>
      </div>

      <h2 class="margin-y text-bold">Real Voices, Endless Insights</h2>

      <div class="row q-mb-md q-pb-md">
        <div class="col-xl-10 col-lg-col-xl-10 col-md-col-xl-10 col-sm-col-xl-10 col-xs-12">
          <div class="q-py-md relative-position full-width" >
            <q-carousel
                v-model="slide"
                swipeable
                infinite
                animated
                autoplay="1000"
                transition-prev="slide-right"
                transition-next="slide-left"
                control-color="white"
                prev-icon="mdi-arrow-left"
                next-icon="mdi-arrow-right"
                navigation-icon="mdi-circle-small"
                navigation
                padding
                :arrows="false"
                :height="isMobile ? '500px' : '400px'"
                class="rounded-borders bg-transparent q-ma-sm"
            >
              <q-carousel-slide :name="index" class="column no-wrap flex-center"
                                v-for="(testimonial, index) in testimonials" :key="index">
                <q-item class="bg-glass rounded-borders" :style="isMobile ? 'height:340px' : 'height:275px'"
                        v-if="testimonial.name !== 'Submit'">
                  <q-img class="testimonial-image" :src="testimonial.profileURL" />
                  <q-item-section class="bg-glass-dark q-ma-xl q-pa-sm text-left rounded-borders shadow-1 inline-block">
                    <h5 class="text-white q-my-none">{{ testimonial.name }}</h5>
                    <div class="text-white">{{ testimonial.text }}</div>
                  </q-item-section>
                </q-item>
                <q-item v-else>
                  <q-item-section class="text-center">
                    Submit Your Own Testimonial!
                    <a class="text-underline" target="_blank" href="mailto:admin@swordmoon.com">Email Us</a>
                  </q-item-section>
                </q-item>
              </q-carousel-slide>
            </q-carousel>
          </div>
        </div>
      </div>

      <div class="col-12" style="align-content: center">
        <q-item class="bg-glass-dark rounded-borders padding-y q-my-xl q-ma-sm fade-in fade-in-up">
          <q-item-section>
            <h3 class="text-bold q-mt-none text-white">Achieve self-discovery & mental improvement with Sword Moon.</h3>
            <q-item class="flex-center">
              <q-btn icon="mdi-arrow-top-right"
                     :class="(isDarkMode ? 'bg-dark' : 'bg-light')" rounded unelevated
                     :to="'/signup'">
                Get Started Today
              </q-btn>
            </q-item>
          </q-item-section>
        </q-item>
      </div>

      <div class="q-ma-sm">
        <p>Sword Moon is a concept realized <a class="text-underline" target="_blank" href="https://bymar.co">ByMar.co</a></p>
      </div>

    </div>
  </section>
</template>

<script>
import { mapState } from "vuex";

export default {
  components: {
  },
  beforeMount() {
  },
  async mounted() {
    this.setupIntersectionObserver();
  },
  async unmounted() {
  },
  data() {
    return {
      URL,
      isLoading: false,
      slide: 1,
      testimonials: [
        {
          name: 'Bethany O.',
          profileURL: '/images/user-3.png',
          text: '"I was blown away by how quickly this AI therapist helped me turn things around. Don\'t believe\n' +
        '        me? Try it for yourself and see the difference! 24/7 support is a total game-changer."',
        },
        {
          name: 'Hamza L.',
          profileURL: '/images/user-2.png',
          text: '"Hands down, this AI therapy app has been the most effective way to get help on my schedule.\n' +
              '    The conversations feel super natural and the insights are spot-on. What are you waiting for?"',
        },
        {
          name: 'Dave M.',
          profileURL: '/images/user-1.png',
          text: '"This AI therapy is literally the best thing that\'s happened to my mental health. The way it\n' +
              '    adapts to my needs is pure genius. Don\'t miss out on this opportunity to level up your\n' +
              '    well-being!"',
        },
        {
          name: 'Submit',
        }
      ]
    };
  },
  computed: {
    ...mapState([
      'userProfile',
      'currentUser',
    ]),
    isDarkMode() {
      return this.$q.dark.isActive;
    },
    isMobile() {
      return (window.innerWidth < 1024);
    },
  },
  methods: {
    setupIntersectionObserver() {
      const options = {
        root: null,
        rootMargin: '0px',
        threshold: 0.5,
      };

      const callback = (entries, observer) => {
        entries.forEach((entry) => {
          if (entry.isIntersecting) {
            entry.target.classList.add('visible');
          } else {
            entry.target.classList.remove('visible');
          }
        });
      };

      const observer = new IntersectionObserver(callback, options);

      const elements = document.querySelectorAll('.fade-in');
      elements.forEach((element) => {
        observer.observe(element);
      });
    },
  },
  watch: {
  }
};
</script>

<style scoped lang="scss">
.padding-y {
  padding-top: 100px;
  padding-bottom: 100px;
}
.margin-y {
  margin-top: 120px;
  margin-bottom: 120px;
}
.home-image {
  border-radius: $generic-border-radius;
  min-height: 1000px;
  width: 100%;
  height: 100%;
  object-fit: cover;
  box-shadow: 0 0 12px 0 rgba(0, 0, 0, 0.5);

  @media screen and (max-width: 599.99px) {
    min-height: calc(100vw);
  }
}
.testimonial-image {
  border-radius: 100px;
  height: 100px;
  width: 100px;
  top: -10px;
  left: -10px;
  position: absolute;

  @media screen and (max-width: 599.99px) {
    height: 80px;
    width: 80px;
  }
}
ul {
  list-style-type: none;
  li {
    margin-bottom: 1em;
    position: relative;
    left: -1em;
    &:before {
      content: "";
      position: absolute;
      width: 1.5em;
      height: 1.5em;
      margin-left: -2em;
    }
  }
}
.fade-in {
  opacity: 0;
  transform: translateX(-50px);
  transition: opacity 1s ease-out, transform 1s ease-out;
}

.fade-in-left {
  transform: translateX(-50px);
}

.fade-in-left-delay {
  transform: translateX(-50px);
  transition-delay: 0.5s;
}

.fade-in-up {
  transform: translateY(50px);
}

.fade-in-down {
  transform: translateY(-50px);
}

.fade-in-right {
  transform: translateX(50px);
}

.fade-in-right-delay {
  transform: translateX(50px);
  transition-delay: 0.5s;
}

.fade-in.visible {
  opacity: 1;
  transform: translateX(0);
}
</style>
<template>
  <section>

    <HeaderNav></HeaderNav>

    <section class="q-custom-content">
      <div class="auth-container text-center is-fullwidth">

        <!-- LOGIN -->
        <div class="row full-height">
          <div class="col-12 relative-position full-height">

            <q-card class="absolute-center text-center bg-glass" style="width:100%;max-width:500px;">
              <q-form ref="loginForm" class="q-ma-md" @submit="login()">
                <div class="q-mt-lg">
                  Don't have an account? <router-link class="text-accent text-underline text-weight-medium" :to="{ name: 'SignUp' }">Sign Up</router-link>
                </div>

                <q-separator class="q-mt-lg q-mb-lg"/>

                <q-input class="q-mb-xs" outlined label="Email" type="email"
                         required
                         v-model.trim="email"
                         lazy-rules
                         :rules="[ val => (/^(?=[a-zA-Z0-9@._%+-]{6,254}$)[a-zA-Z0-9._%+-]{1,64}@(?:[a-zA-Z0-9-]{1,63}\.){1,8}[a-zA-Z]{2,63}$/.test(val)) || 'Please enter a valid email' ]"
                ></q-input>
                <q-input class="q-mb-xs" outlined label="Password" type="password"
                         required
                         v-model.trim="password"
                         lazy-rules
                         :rules="[ val => val.length > 5 || 'Please user more than 5 characters' ]"
                ></q-input>

                <q-btn
                    class="full-width"
                    unelevated rounded color="accent" text-color="dark" size="lg"
                    type="submit"
                    :disable="isLoading">
                  Log In
                </q-btn>

                <q-separator class="q-mt-lg q-mb-lg"/>

                <div>
                  If you click “Log In”, you agree with our <router-link class="text-accent text-underline text-weight-medium" :to="{ name: 'Terms' }">Terms Of Service</router-link> and <router-link class="text-accent text-underline text-weight-medium" :to="{ name: 'Privacy' }">Privacy Policy</router-link>.
                </div>

              </q-form>
            </q-card>

          </div>
        </div>

        <div id="recaptcha-container"></div>

      </div>
    </section>

  </section>
</template>

<script>
import { mapState } from 'vuex';
import { SendSuccess, SendError } from "@/helpers.js";
import { Capacitor } from "@capacitor/core";

import HeaderNav from '@/components/blocks/HeaderNav.vue';

import { auth, RecaptchaVerifier, FirebaseAuthentication, EmailAuthProvider, signInWithCredential } from '../../firebaseConfig.js';

export default {
  head() {
    return {
      title: 'Login',
      titleTemplate: (title) => `${title} | Sword Moon`,
      meta: [
      ],
    };
  },
  components: {
    HeaderNav,
  },
  computed: {
    ...mapState([
      'userProfile',
      'isLoading',
    ]),
  },
  beforeMount() {
  },
  mounted() {
    window.recaptchaVerifier = new RecaptchaVerifier(auth, 'recaptcha-container', {
      size: 'invisible',
      callback: (response) => {
        // reCAPTCHA solved, allow signInWithPhoneNumber.
        // console.log('recaptchaVerifier', response);
      },
      'expired-callback': (response) => {
        // Response expired. Ask user to solve reCAPTCHA again.
        // console.log('recaptchaVerifier expired', response);
      }
    });
  },
  data() {
    return {
      email: '',
      password: '',
    };
  },
  methods: {
    async login() {
      try {
        if (this.$refs.loginForm.valid) {
          return;
        }
        this.$store.commit('isLoading', true);

        await FirebaseAuthentication.signInWithEmailAndPassword({
          email: this.email,
          password: this.password,
        });

        // Native Platform Auth
        if (Capacitor.isNativePlatform()) {
          const credential = EmailAuthProvider.credential(
            this.email,
            this.password,
          );
          await signInWithCredential(auth, credential);
        }

        await this.routeAfterLogin();
      } catch(err) {
        this.$store.commit('isLoading', false);
        this.handleError(err);
      }
    },
    async routeAfterLogin() {
      // Done in firebaseListeners
      this.$store.commit('isLoggingIn', true);
    },
    handleError(err) {
      if (err?.code?.includes('auth/invalid-login-credentials') ||
          err?.code?.includes('auth/invalid-credential')) {
        SendError('Invalid login username or password. Please try again');
      } else if (err?.code?.includes('auth/too-many-requests')) {
        SendError('Cannot validate you, please try again later');
      } else {
        SendError(err);
      }
      this.isLoading = false;
    },
  },
  watch: {
  }
};
</script>

<style scoped lang="scss">
.auth-container {
  min-height: calc(100vh - 49px);
  height: calc(100vh - 49px);
  max-width: 1200px;
  margin-left: auto;
  margin-right: auto;

  @media screen and (max-width: 599.99px) {
    padding-top: 50px!important;
    padding-bottom: 50px!important;
    margin: 0!important;
  }
}
</style>
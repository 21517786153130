<template>
  <q-dialog ref="dialogRef" @hide="onDialogHide">
    <q-card class="modal-container">
      <q-card-section>
        <q-toolbar class="q-pa-none">
          <q-toolbar-title class="text-white">Buy Minutes</q-toolbar-title>
          <q-btn class="q-pa-xs bg-white border-light text-dark" rounded unelevated
                 @click="onDialogHide">
            <q-icon name="mdi-close" size="md" />
          </q-btn>
        </q-toolbar>
      </q-card-section>

      <!-- PAYMENT -->
      <q-card-section v-if="!isLoaded">
        <div class="justify-center flex q-ma-xl">
          <q-spinner
              color="grey"
              size="3em"
          />
        </div>
      </q-card-section>

      <!-- PAYMENT -->
      <q-card-section v-else>
        <div id="checkout"></div>
      </q-card-section>

      <!-- BUTTONS -->
      <q-card-section>
        <q-toolbar class="q-pa-none">
          <q-btn
              unelevated rounded color="primary" text-color="white"
              type="button" @click="onCancelClick" style="min-width:100px;">
            Cancel
          </q-btn>
        </q-toolbar>
      </q-card-section>

    </q-card>
  </q-dialog>
</template>

<script>
import { useDialogPluginComponent } from 'quasar';
import { mapState } from 'vuex';

const stripe = window.Stripe(process.env.STRIPE_KEY);

import { SendSuccess, SendError } from '@/helpers.js';

export default {
  components: {
  },
  async beforeMount() {
  },
  props: {
    organizationId: String,
  },
  emits: [
    ...useDialogPluginComponent.emits,
  ],
  async mounted() {
    await this.$store.dispatch('getPricing');
    await this.createPayment();
  },
  async unmounted() {
    // Unmount Checkout
    if (this.checkout) {
      this.checkout.destroy();
    }
  },
  data() {
    return {
      isLoaded: false,
      paymentLoading: true,
      checkout: null,
    };
  },
  computed: {
    ...mapState([
      'userProfile',
      'currentUser',
      'pricing',
    ]),
  },
  methods: {
    onDialogHide() {
      this.$emit('hide');
    },
    onOKClick() {
      this.$emit('ok', {});
      this.onDialogHide();
    },
    onCancelClick() {
      this.$emit('hide');
    },
    //
    async createPayment() {
      try {
        const priceId = this.pricing.web[0].priceId;

        const response = await this.$store.dispatch('createPayment', {
          priceId,
        });

        this.isLoaded = true;
        SendSuccess(response.message);

        this.checkout = await stripe.initEmbeddedCheckout({
          clientSecret: response.clientSecret
        });

        // Mount Checkout
        this.checkout.mount('#checkout');
      } catch (err) {
        SendError(err);
      }
    },
  },
  watch: {
  }
};
</script>

<style scoped lang="scss">
.modal-container {
  background: $dark;
  min-width: 60%;
  @media screen and (max-width: 599.99px) {
    min-width: 100%;
  }
}
</style>

<style lang="scss">
#checkout {
  iframe {
    border-radius: $generic-border-radius;
  }
}
</style>
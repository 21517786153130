// firebaseConfig.js

import { Capacitor } from "@capacitor/core";
import { initializeApp } from "firebase/app";
import {
  getAuth,
  RecaptchaVerifier,
  getIdToken,
  signInWithPhoneNumber,
  signOut,
  initializeAuth,
  PhoneAuthProvider,
  signInWithCredential,
  indexedDBLocalPersistence,
  signInWithEmailAndPassword,
  createUserWithEmailAndPassword,
  EmailAuthProvider,
  onAuthStateChanged,
  GoogleAuthProvider,
  OAuthProvider,
  sendPasswordResetEmail,
} from 'firebase/auth';

import { FirebaseAuthentication } from '@capacitor-firebase/authentication';

import { getAnalytics } from "firebase/analytics";

import {
  getFirestore,
  Timestamp,
  query,
  orderBy,
  limit,
  collection,
  where,
  getDocs,
  doc,
  updateDoc,
  onSnapshot,
} from "firebase/firestore";

import {
  getStorage,
  ref,
  uploadBytes,
  getDownloadURL,
} from "firebase/storage";

const config = {
  apiKey: process.env.GOOGLE_APIKEY,
  authDomain: process.env.GOOGLE_AUTHDOMAIN,
  projectId: process.env.GOOGLE_PROJECTID,
  storageBucket: process.env.GOOGLE_STORAGEBUCKET,
  messagingSenderId: process.env.GOOGLE_MESSAGINGSENDERID,
  appId: process.env.GOOGLE_APPID,
  measurementId: process.env.GOOGLE_MEASUREMENTID,
};

const app = initializeApp(config);
const firestore = getFirestore(app);
const storage = getStorage(app, process.env.GOOGLE_STORAGEBUCKET);
const analytics = getAnalytics(app);

let auth;

if (Capacitor.isNativePlatform()) {
  auth = initializeAuth(app, {
    persistence: indexedDBLocalPersistence,
  });
} else {
  auth = getAuth(app);
  auth.settings.appVerificationDisabledForTesting = false;
}

export {
  FirebaseAuthentication, // Capacitor
  RecaptchaVerifier,
  getIdToken,
  signInWithPhoneNumber,
  signInWithEmailAndPassword,
  createUserWithEmailAndPassword,
  auth, // getAuth
  PhoneAuthProvider,
  signInWithCredential,
  EmailAuthProvider,
  GoogleAuthProvider,
  OAuthProvider,
  sendPasswordResetEmail,
  onAuthStateChanged,
  signOut,
  firestore,
  analytics,
  Timestamp,
  collection,
  orderBy,
  limit,
  query,
  where,
  onSnapshot,
  doc,
  getDocs,
  updateDoc,
  storage,
  ref,
  uploadBytes,
  getDownloadURL,
};

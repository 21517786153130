class AudioPlayerQueue {
  constructor(element) {
    this.queue = []; // Queue to hold the audio blobs
    this.isPlaying = false; // Flag to track playback status
    this.isMuted = false; // Flag to track mute status

    this.element = element;
  }

  // Method to add audioBlob to the queue and start playback if not already playing
  enqueue(base64Audio) {
    // Decode Base64 string to a byte array
    const audioBytes = this.base64ToArrayBuffer(base64Audio);

    // Convert the byte array into a Blob
    const audioBlob = new Blob([audioBytes], { type: 'audio/wav' });

    this.queue.push(audioBlob); // Add the new audioBlob to the queue
    if (!this.isPlaying) {
      this.playNext(); // Start playback if not already playing
    }
  }

  // Method to play the next audioBlob in the queue
  playNext() {
    if (this.queue.length === 0) {
      this.isPlaying = false; // No more audio to play
      return;
    }

    this.isPlaying = true;

    const audioBlob = this.queue.shift(); // Remove the first audioBlob from the queue
    // const audioUrl = URL.createObjectURL(audioBlob); // Create a URL for the blob

    // Use FileReader to read the blob
    const reader = new FileReader();

    reader.onload = () => {
      this.element.src = reader.result; // This is a data URL
      this.element.load();
      this.element.play().then(() => {
        console.log('Playing Audio');
      }).catch((error) => {
        console.error('Error playing audio:', error);
      });

      this.element.onended = () => {
        console.log('Audio ended');
        this.playNext(); // No need to revokeObjectURL when using FileReader
      };
    };

    // Handle read errors (optional but recommended)
    reader.onerror = (error) => {
      console.error('Error reading audio blob:', error);
      this.isPlaying = false; // Ensure playback is reset
    };

    // Read the audio blob as a data URL
    reader.readAsDataURL(audioBlob);
  }

  pause() {
    this.element.pause();
    this.isPlaying = false;
  }

  resume() {
    // this.element.play();
  }

  clear() {
    this.queue = [];
  }

  base64ToArrayBuffer(base64) {
    const binaryString = window.atob(base64); // Decode base64
    const len = binaryString.length;
    const bytes = new Uint8Array(len);
    for (let i = 0; i < len; i++) {
      bytes[i] = binaryString.charCodeAt(i);
    }
    return bytes.buffer; // Return an ArrayBuffer
  }

  setMuted(isMuted) {
    this.isMuted = isMuted;
    this.element.muted = isMuted;
  }
}

export default AudioPlayerQueue;
<template>
  <section class="q-custom-menu">

    <div class="q-custom-menu-panel"
         :style="`width:${drawer ? '244' : '0'}px;`"
         v-if="drawer"
         @click="toggleDrawer()">
      <!-- placeholder -->
    </div>

    <transition name="menu">
      <div class="fixed"
           :class="isDarkMode ? 'text-white' : 'text-dark'"
           :style="`width:${drawer ? '244' : '0'}px;`"
           v-if="drawer">
        <div class="fit column justify-between">
          <q-card class="rounded-borders q-ml-sm q-mr-sm q-mb-sm bg-glass">

            <q-list seperator class="q-mt-lg q-mb-lg">

              <q-item clickable class="bg-accent text-secondary" rounded @click="navigate('/')" v-ripple>
                <q-tooltip anchor="top left" self="center start" :offset="[-15, 0]">
                  Go Back
                </q-tooltip>
                <q-item-section>Back</q-item-section>
              </q-item>

              <q-item>
                <q-item-label class="text-bold q-mt-lg">
                  <q-item-section>Personal</q-item-section>
                </q-item-label>
              </q-item>

              <q-separator></q-separator>

              <q-item clickable
                      v-for="(tab, index) in tabs" :key="index"
                      @click="navigate(tab.path)" v-ripple
                      :class="path.includes(`${tab.path}`) ? (isDarkMode ? 'text-accent bg-secondary' : 'text-accent bg-secondary') : ''">
                <q-tooltip anchor="top left" self="center start" :offset="[-15, 0]">
                  {{tab.description}}
                </q-tooltip>
                <q-item-section>{{tab.name}}</q-item-section>
              </q-item>

              <q-separator></q-separator>

            </q-list>
          </q-card>
        </div>
      </div>
    </transition>

  </section>
</template>

<script>
import { mapState } from 'vuex';

export default {
  components: {
  },
  props: {
  },
  computed: {
    ...mapState([
      'currentUser',
      'userProfile',
      'drawer',
    ]),
    isDarkMode() {
      return this.$q.dark.isActive;
    },
    isMobile() {
      return (window.innerWidth < 1024);
    },
  },
  data() {
    return {
      path: this.$route.path,
      tabs: [
        { name: 'Account', path: '/settings/account', description: 'Manage your account', icon: 'mdi-account-edit' },
        { name: 'Notifications', path: '/settings/notifications', description: 'Manage your notification settings', icon: 'mdi-account-edit' },
        { name: 'Servers', path: '/settings/servers', description: 'Manage your servers', icon: 'mdi-account-edit' },
        { name: 'Payments', path: '/settings/payments', description: 'Manage your payments', icon: 'mdi-account-edit' },
      ]
    };
  },
  methods: {
    navigate(path) {
      this.$router.push(path);
    },
    toggleDrawer() {
      this.$store.commit('setDrawer', !this.drawer);
    },
  },
  async beforeCreate() {
  },
  async beforeMount() {
  },
  mounted() {
  },
  beforeUnmount() {
  },
  watch: {
  },
};
</script>

<style lang="scss">
.settings-drawer {
  border-right: 1px solid rgba(0, 0, 0, 0.12);
}
</style>
<template>
  <section class="q-custom-content">

    <HeaderNav></HeaderNav>

    <div class="row">

      <div class="col-xl-6 col-lg-6 col-md-6 col-sm-2 col-xs-0">
        <div class="panel-auth-parent text-center sm-hide xs-hide">

          <q-img
              class="auth-image"
              :src="`/images/home-image-${showLoginForm ? 3 : (showForgotPassword ? 4 : 5) }.png`"
              alt="Login Image">
            <video class="auth-image absolute-center" :controls="false" autoplay loop muted  style="opacity:0.6">
              <source :src="`/videos/video-${showLoginForm ? 1 : (showForgotPassword ? 2 : 3) }.mp4`" type="video/mp4">
            </video>
            <div class="absolute-center rounded-borders bg-glass">
              <h4 class="text-white">{{showLoginForm ? 'Welcome Back' : (showForgotPassword ? 'Let\'s help you out' : 'Start Something New') }}</h4>
              <p class="q-mb-xl q-ma-lg">{{showLoginForm ? 'We\'re happy that you took some time out of your day.' : (showForgotPassword ? 'This shouldn\'t take long.' : 'Let\'s continue the journey, together.') }}</p>
            </div>
          </q-img>

        </div>
      </div>

      <div class="col-xl-6 col-lg-6 col-md-6 col-sm-8 col-xs-12">
        <q-card class="panel-auth-parent text-center bg-transparent">
          <div class="panel-auth-child bg-glass q-pa-lg">

            <!-- LOGIN -->
            <q-form v-if="showLoginForm" ref="loginForm" @submit="emailLogin()">
              <div>
                Don't have an account? <q-btn
                  class=""
                  unelevated rounded color="secondary" text-color="white" size="md"
                  type="button"
                  :to="{ name: 'SignUp' }"
                  label="Sign Up"
                  :disable="isLoading">
              </q-btn>
              </div>

              <q-separator class="q-mt-lg q-mb-lg"/>

              <div class="row q-col-gutter-sm q-mb-sm">
                <div class="col-xl-6 col-lg-6 col-md-6 col-sm-6 col-xs-12">
                  <q-btn
                      class="full-width"
                      unelevated rounded color="dark" text-color="white" size="md"
                      type="button"
                      icon="mdi-google"
                      @click="providerSignup('google')"
                      label="  Login with Google"
                      :disable="isLoading">

                  </q-btn>
                </div>
                <div class="col-xl-6 col-lg-6 col-md-6 col-sm-6 col-xs-12">
                  <q-btn
                      class="full-width"
                      unelevated rounded color="dark" text-color="white" size="md"
                      type="button"
                      icon="mdi-apple"
                      @click="providerSignup('apple')"
                      label="  Login with Apple"
                      :disable="isLoading">
                  </q-btn>
                </div>
              </div>

              <p class="text-accent q-mt-lg q-mb-lg">- or -</p>

              <q-input class="q-mb-xs" outlined label="Email" type="email"
                       required
                       v-model.trim="loginForm.email"
                       lazy-rules
                       :rules="[ val => (/^(?=[a-zA-Z0-9@._%+-]{6,254}$)[a-zA-Z0-9._%+-]{1,64}@(?:[a-zA-Z0-9-]{1,63}\.){1,8}[a-zA-Z]{2,63}$/.test(val)) || 'Please enter a valid email' ]"
              ></q-input>
              <q-input class="q-mb-xs" outlined label="Password" type="password"
                       required
                       v-model.trim="loginForm.password"
                       lazy-rules
                       :rules="[ val => val.length > 5 || 'Please user more than 5 characters' ]"
              ></q-input>

              <q-btn
                  unelevated rounded color="accent" text-color="dark" size="md"
                  type="submit"
                  icon="mdi-email"
                  label="  Login with Email"
                  :disable="isLoading">
              </q-btn>

              <q-separator class="q-mt-lg q-mb-lg"/>

              <div>
                Forgot Password? <q-btn
                  class=""
                  unelevated rounded color="accent" text-color="dark" size="md"
                  type="button"
                  :to="{ name: 'ResetPassword' }"
                  label="Reset Password"
                  :disable="isLoading">
              </q-btn>
              </div>

            </q-form>

            <!-- SIGNUP -->
            <q-form v-if="!showLoginForm && !showForgotPassword" ref="signupForm" @submit="emailSignup()">
              <div>
                Already have an account? <q-btn
                  class=""
                  unelevated rounded color="secondary" text-color="white" size="md"
                  type="button"
                  :to="{ name: 'Login' }"
                  label="Log In"
                  :disable="isLoading">
              </q-btn>
              </div>

              <q-separator class="q-mt-lg q-mb-lg"/>

              <div class="row q-col-gutter-sm q-mb-sm">
                <div class="col-xl-6 col-lg-6 col-md-6 col-sm-6 col-xs-12">
                  <q-btn
                      class="full-width"
                      unelevated rounded color="dark" text-color="white" size="md"
                      type="button"
                      icon="mdi-google"
                      @click="providerSignup('google')"
                      label="  Signup with Google"
                      :disable="isLoading">

                  </q-btn>
                </div>
                <div class="col-xl-6 col-lg-6 col-md-6 col-sm-6 col-xs-12">
                  <q-btn
                      class="full-width"
                      unelevated rounded color="dark" text-color="white" size="md"
                      type="button"
                      icon="mdi-apple"
                      @click="providerSignup('apple')"
                      label="  Signup with Apple"
                      :disable="isLoading">
                  </q-btn>
                </div>
              </div>

              <p class="text-accent q-mt-lg q-mb-lg">- or -</p>

              <q-input class="q-mb-xs" outlined label="Email" type="email"
                       required
                       v-model.trim="signupForm.email"
                       lazy-rules
                       :rules="[ val => (/^(?=[a-zA-Z0-9@._%+-]{6,254}$)[a-zA-Z0-9._%+-]{1,64}@(?:[a-zA-Z0-9-]{1,63}\.){1,8}[a-zA-Z]{2,63}$/.test(val)) || 'Please enter a valid email' ]"
              ></q-input>
              <q-input class="q-mb-xs" outlined label="Password" type="password"
                       required
                       v-model.trim="signupForm.password"
                       lazy-rules
                       :rules="[ val => val.length > 5 || 'Please user more than 5 characters' ]"
              ></q-input>
              <q-input class="q-mb-xs" outlined label="Verify Password" type="password"
                       required
                       v-model.trim="signupForm.verifyPassword"
                       lazy-rules
                       :rules="[ val => val === this.signupForm.password || 'Ensure passwords match' ]"
              ></q-input>

              <q-btn
                  class=""
                  unelevated rounded color="accent" text-color="dark" size="md"
                  type="submit"
                  icon="mdi-email"
                  label="  Signup with Email"
                  :disable="isLoading">
              </q-btn>

              <div class="text-subtitle2 q-mt-lg">
                If you “Sign Up”, you agree with our <br> <router-link class="text-accent text-underline text-weight-medium" :to="{ name: 'Terms' }">Terms Of Service</router-link> and <router-link class="text-accent text-underline text-weight-medium" :to="{ name: 'Privacy' }">Privacy Policy</router-link>.
              </div>

              <q-separator class="q-mt-lg q-mb-lg"/>

              <div class="q-mt-sm">
                Forgot Password? <q-btn
                  class=""
                  unelevated rounded color="accent" text-color="dark" size="md"
                  type="button"
                  :to="{ name: 'ResetPassword' }"
                  label="Reset Password"
                  :disable="isLoading">
              </q-btn>
              </div>

            </q-form>

            <!-- FORGOT PASSWORD -->
            <q-form v-if="showForgotPassword" ref="resetPasswordForm" @submit="resetPassword()">
              <div v-if="!passwordResetSuccess">

                <div class="">
                  <p class="">We will send you an email to reset your password</p>
                </div>

                <q-input outlined label="Email" type="email"
                         required
                         v-model.trim="passwordForm.email"
                         lazy-rules
                         :rules="[ val => (/^(?=[a-zA-Z0-9@._%+-]{6,254}$)[a-zA-Z0-9._%+-]{1,64}@(?:[a-zA-Z0-9-]{1,63}\.){1,8}[a-zA-Z]{2,63}$/.test(val)) || 'Please enter a valid email' ]"
                >
                  <template v-slot:append>
                    <q-btn
                        class=""
                        unelevated rounded color="accent" text-color="dark" size="md"
                        type="submit"
                        icon="mdi-lock-reset"
                        label="  Reset Password"
                        :disable="isLoading">
                    </q-btn>
                  </template>
                </q-input>
              </div>

              <div v-else>
                <h2 class="">Email Sent</h2>
                <div class="">Check your email for a link to reset your password.</div>
              </div>

              <q-separator class="q-mt-lg q-mb-lg"/>

              <q-btn
                  class=""
                  unelevated rounded color="accent" text-color="dark" size="md"
                  type="button"
                  :to="{ name: 'Login' }"
                  label="Back To Login"
                  :disable="isLoading">
              </q-btn>
            </q-form>

          </div>
        </q-card>
      </div>

    </div>

    <div id="recaptcha-container"></div>
  </section>
</template>

<script>
import { mapState } from 'vuex';
import { Capacitor } from "@capacitor/core";

import HeaderNav from '@/components/blocks/HeaderNav.vue';

import { SendError, SendSuccess } from "@/helpers";

import {
  auth,
  RecaptchaVerifier,
  EmailAuthProvider,
  FirebaseAuthentication,
  signInWithCredential,
  GoogleAuthProvider,
  OAuthProvider,
  sendPasswordResetEmail,
} from "@/firebaseConfig";

export default {
  components: {
    HeaderNav,
  },
  data() {
    return {
      loginForm: {
        email: '',
        password: ''
      },
      signupForm: {
        email: '',
        password: ''
      },
      passwordForm: {
        email: ''
      },
      showLoginForm: (this.$route.path.includes('/login') || false),
      showForgotPassword: (this.$route.path.includes('/reset-password') || false),
      passwordResetSuccess: false,
    };
  },
  computed: {
    ...mapState([
      'currentUser',
      'userProfile',
      'isLoading',
    ])
  },
  methods: {
    async emailLogin() {
      try {
        this.$store.commit('isLoading', true);

        await FirebaseAuthentication.signInWithEmailAndPassword({
          email: this.loginForm.email,
          password: this.loginForm.password,
        });

        // Native Platform Auth
        if (Capacitor.isNativePlatform()) {
          const credential = EmailAuthProvider.credential(this.loginForm.email, this.loginForm.password);
          await signInWithCredential(auth, credential);
        }

        await this.routeAfterAuth();
      } catch(err) {
        this.handleError(err);
      }
    },
    async emailSignup() {
      try {
        this.$store.commit('isLoading', true);

        await FirebaseAuthentication.createUserWithEmailAndPassword({
          email: this.signupForm.email,
          password: this.signupForm.password,
        });

        // Native Platform Auth
        if (Capacitor.isNativePlatform()) {
          const credential = EmailAuthProvider.credential(this.signupForm.email, this.signupForm.password);
          await signInWithCredential(auth, credential);
        }

        await this.routeAfterAuth();
      } catch(err) {
        this.handleError(err);
      }
    },
    async providerSignup(provider) {
      try {
        this.$store.commit('isLoading', true);

        let result;
        if (provider === 'google') {
          result = await FirebaseAuthentication.signInWithGoogle();
        } else if (provider === 'apple') {
          // { skipNativeAuth: true } required for Apple
          result = await FirebaseAuthentication.signInWithApple({ skipNativeAuth: true });
        }

        // Native Platform Auth
        if (Capacitor.isNativePlatform()) {
          if (provider === 'google') {
            const credential = GoogleAuthProvider.credential(result.credential.idToken, result.credential.accessToken);
            await signInWithCredential(auth, credential);
          } else if (provider === 'apple') {
            const provider = new OAuthProvider('apple.com');
            const credential = provider.credential({
              idToken: result.credential.idToken,
              rawNonce: result.credential.nonce, // result.additionalUserInfo.profile.nonce
            });
            // Must set result here for Apple
            result = await signInWithCredential(auth, credential);
          }
        }

        await this.routeAfterAuth();
      } catch(err) {
        this.handleError(err);
      }
    },
    async resetPassword() {
      try {
        this.$store.commit('isLoading', true);
        await sendPasswordResetEmail(auth, this.passwordForm.email);
        this.$store.commit('isLoading', false);
        this.passwordResetSuccess = true;
        this.passwordForm.email = '';
      } catch(err) {
        SendError(err);
      }
    },
    async routeAfterAuth() {
      // Done in firebaseListeners
      this.$store.commit('isLoggingIn', true);
    },
    handleError(err) {
      console.error(err);

      if (err?.code?.includes('auth/invalid-credential')) {
        SendError('Invalid login username or password. Please try again');
      } else if (err?.code?.includes('auth/wrong-password')) {
        SendError('Incorrect password, please try again.');
      } else if (err?.code?.includes('auth/invalid-login-credentials')) {
        SendError('Invalid login username or password. Please try again');
      } else if (err?.code?.includes('auth/too-many-requests')) {
        SendError('Cannot validate you, please try again later');
      } else if (err?.code?.includes('auth/weak-password')) {
        SendError('Please use a more secure password to signup.');
      } else if (err?.code?.includes('auth/email-already-in-use')) {
        SendError('There is an account with that email already');
      } else if (err?.code?.includes('auth/popup-closed-by-user') || err?.code?.includes('auth/cancelled-popup-request')) {
        SendError('Dismissed authentication');
      } else {
        SendError(err);
      }
      this.$store.commit('isLoading', false);
    },
  },
  mounted() {
    if (this.currentUser) {
      this.$router.push('/s');
    }

    window.recaptchaVerifier = new RecaptchaVerifier(auth, 'recaptcha-container', {
      size: 'invisible',
      callback: (response) => {
        // reCAPTCHA solved, allow signInWithPhoneNumber.
        // console.log('recaptchaVerifier', response);
      },
      'expired-callback': (response) => {
        // Response expired. Ask user to solve reCAPTCHA again.
        // console.log('recaptchaVerifier expired', response);
      }
    });
  },
  beforeRouteEnter (to, from, next) {
    next(vm => {
      vm.showLoginForm = to.path.includes('/login') || false;
      vm.showForgotPassword = to.path.includes('/reset-password') || false;
    });
  },
};
</script>

<style scoped lang="scss">
.auth-image {
  width: 100%;
  height: 100%;
  object-fit: cover;
  box-shadow: 0 0 12px 0 rgba(0, 0, 0, 0.5);
}
.panel-auth-parent {
  display: flex;
  flex-direction: column;
  height: calc(100vh - env(safe-area-inset-top) - env(safe-area-inset-bottom));
}
.panel-auth-child {
  max-width: 600px;
  width: 80%;

  position: absolute;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);

  @media screen and (max-width: 599.99px) {
    width: 95%;
    top: 100px;
    transform: translate(-50%, 0);
  }
}
</style>
<template>
  <section class="q-custom-menu">

    <div class="q-custom-menu-panel"
         :style="`width:${drawer ? '340' : '0'}px;`"
         v-if="drawer"
         @click="toggleDrawer()">
      <!-- placeholder -->
    </div>

    <transition name="menu">
      <div class="fixed"
           :class="isDarkMode ? 'text-white' : 'text-dark'"
           :style="`width:${drawer ? '340' : '0'}px;`"
            v-if="drawer">
        <div class="fit column justify-between">
          <q-card class="panel-parent rounded-borders q-ml-sm q-mr-sm q-mb-sm bg-glass">
            <q-item>
              <q-item-section>
                <q-btn class="full-width" color="light" :class="(isDarkMode ? 'bg-dark' : 'bg-secondary')" rounded unelevated
                       @click="createSession">
                  New Session
                </q-btn>
              </q-item-section>
            </q-item>

            <q-separator></q-separator>

            <q-scroll-area ref="sessions-scroll"
                           class="panel-center-child">

              <q-item class="text-left" clickable v-ripple
                      @mouseenter="showSettings(index)"
                      @mouseleave="hideSettings"
                      v-for="(session, index) in sessions" :key="index"
                      :class="path.includes(`${session.sessionId}`) ? (isDarkMode ? 'text-accent bg-secondary' : 'text-accent bg-secondary') : ''">
                <q-tooltip anchor="top left" self="center start" :offset="[-15, 0]">
                  {{ moment.unix(session.createdAt.seconds).fromNow() }}
                </q-tooltip>
                <q-item-section @click="navigate(`/s/${session.sessionId}`)">
                    <span class="session-message" v-if="session.lastMessage">
                      {{ session.lastMessage.text.slice(0, 25) }}...
                    </span>
                  <span class="session-message" v-else>
                      No messages
                    </span>
                </q-item-section>
                <q-item-section side v-if="setting === index">

                  <q-btn-dropdown
                      class="bg-accent text-light"
                      @before-show="settingsLock = true"
                      @before-hide="settingsLock = false"
                      no-icon-animation dropdown-icon="mdi-dots-horizontal" rounded unelevated flat size="sm">
                    <div>
                      <q-item clickable v-ripple @click="navigate(`/s/${session.sessionId}`)">
                        <q-item-section>Open</q-item-section>
                      </q-item>
                      <q-item clickable v-ripple @click="deleteSession(session.sessionId)">
                        <q-item-section>Delete</q-item-section>
                      </q-item>
                    </div>
                  </q-btn-dropdown>

                </q-item-section>
              </q-item >
            </q-scroll-area>
          </q-card>
        </div>
      </div>
    </transition>

  </section>
</template>

<script>
import moment from 'moment';
import { mapState } from 'vuex';

import { SendError, SendSuccess } from "@/helpers";
import { removeMessageListener } from "@/firebaseListeners";

export default {
  components: {
  },
  computed: {
    ...mapState([
      'currentUser',
      'userProfile',
      'drawer',
      'sessions',
    ]),
    isDarkMode() {
      return this.$q.dark.isActive;
    },
    isMobile() {
      return (window.innerWidth < 1024);
    },
  },
  data() {
    return {
      moment,
      path: this.$route.path,
      setting: -1,
      settingsLock: false,
    };
  },
  methods: {
    navigate(path) {
      // this.$store.commit('setDrawer', false);
      this.$router.push(path);
    },
    showSettings(index) {
      if (this.settingsLock) {
        return;
      }
      this.setting = index;
    },
    hideSettings() {
      if (this.settingsLock) {
        return;
      }
      this.setting = -1;
    },
    toggleDrawer() {
      this.$store.commit('setDrawer', !this.drawer);
    },
    async createSession() {
      try {
        const response = await this.$store.dispatch('createSession');
        this.$router.push(`/s/${response.sessionId}`);
      } catch(err) {
        SendError(err);
      }
    },
    async deleteSession(sessionId) {
      try {
        this.$router.push('/s');
        removeMessageListener();
        const response = await this.$store.dispatch('deleteSession', { sessionId });
        SendSuccess(response.message);
      } catch(err) {
        SendError(err);
      }
    },
  },
  mounted() {
  },
  unmounted() {
  },
  watch: {
  },
};
</script>

<style lang="scss">
.session-message {
  white-space: nowrap;
  overflow: hidden;
  text-overflow: ellipsis;
  line-height: 32px;
  max-width: 240px;
}
</style>
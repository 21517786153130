<template>
  <q-footer class="footer-bar bg-transparent q-mb-sm">
    <div class="rounded-borders full-width q-ma-sm bg-glass flex">
      <q-tabs align="left">
        <q-btn class="q-ma-sm bg-transparent text-secondary" unelevated
               style="min-width:80px;">
          ⓒ {{ new Date().getFullYear() }}
        </q-btn>
      </q-tabs>
      <q-space></q-space>
      <q-tabs align="right">
        <q-btn class="q-mr-sm" name="terms" to="/terms" rounded unelevated
               :class="isDarkMode ? 'text-dark bg-light' : 'text-white bg-accent'"
               style="min-width:80px;">
          Terms
        </q-btn>
        <q-btn class="q-mr-sm" name="privacy" to="/privacy" rounded unelevated
               :class="isDarkMode ? 'text-dark bg-light' : 'text-white bg-accent'"
               style="min-width:80px;">
          Privacy
        </q-btn>
      </q-tabs>
    </div>
  </q-footer>
</template>


<script>
import { mapState, mapGetters } from 'vuex';

export default {
  components: {
  },
  computed: {
    ...mapState([
      'appLoaded',
      'isLoading',
      'currentUser',
      'userProfile',
    ]),
    isDarkMode() {
      return this.$q.dark.isActive;
    },
  },
  data() {
    return {
      path: this.$route.path,
    };
  },
  methods: {
  },
  async beforeCreate() {
  },
  async beforeMount() {
  },
  mounted() {
  },
  beforeUnmount() {
  },
  watch: {
  },
};
</script>

<style scoped lang="scss">
.footer-bar {
  height: 60px;
  padding: 6px;
  justify-content: space-between;
  align-items: center;
  display: flex;
}
</style>
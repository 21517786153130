<template>
  <section>
    <HeaderNav></HeaderNav>

    <q-page-container class="relative-position">

      <SessionDrawer v-if="currentUser && path.includes('/s')"></SessionDrawer>

      <Home v-if="path === '/'"></Home>
      <Chat v-if="path.includes('/s')"></Chat>
      <Profile v-if="path === '/profile'"></Profile>

    </q-page-container>

  </section>
</template>

<script>
import { mapState } from "vuex";

import HeaderNav from '@/components/blocks/HeaderNav.vue';
import SessionDrawer from "@/components/blocks/SessionDrawer.vue";
import Home from '@/components/pages/Home.vue';
import Chat from "@/components/pages/Chat.vue";
import Profile from "@/components/pages/Profile.vue";

export default {
  head() {
    return {
      title: 'Sword Moon',
      titleTemplate: (title) => `${title} | Sword Moon`,
      meta: [
        { name: 'robots', content: 'noindex' },
      ],
    };
  },
  components: {
    HeaderNav,
    SessionDrawer,
    Home,
    Chat,
    Profile,
  },
  async beforeMount() {
  },
  async mounted() {
    console.log(this.path);
  },
  data() {
    return {
      option: null,
      path: this.$route.path,
    };
  },
  computed: {
    ...mapState([
      'userProfile',
      'currentUser',
    ]),
  },
  methods: {
  },
};
</script>

<style scoped lang="scss">
</style>
